import {
  postMessageToShopify,
  ShopifyMessageType,
} from "./post-message-helper";

export const enableIframe = (): void => {
  postMessageToShopify({ type: ShopifyMessageType.ENABLE_WIDGET });
};

export const resizeIframe = (width: number, height: number): void => {
  postMessageToShopify({
    type: ShopifyMessageType.RESIZE_IFRAME,
    width,
    height,
  });
};

export const positionIframe = (pos: {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}): void => {
  postMessageToShopify({
    type: ShopifyMessageType.POSITION_IFRAME,
    position: pos,
  });
};
