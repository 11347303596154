import * as React from "react";
import { ProductList } from "../product/ProductList";
import styles from "./TabView.module.scss";

export const TabView = () => {
  return (
    <div>
      <div className={styles.tabHeader}>
        <div className={styles.tab + " " + styles.active}>Products</div>
      </div>
      <div className={styles.tabBody}>
        <ProductList />
      </div>
    </div>
  );
};
