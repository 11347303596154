import React from "react";
import styles from "./App.module.scss";
import {
  enableIframe,
  resizeIframe,
  positionIframe,
} from "./helper/iframe-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import * as ls from "local-storage";
import {
  getStreamDivDimension,
  getTabDivDimension,
  isVerticalLayout,
  getTotalWidth,
  getTotalHeight,
  getWindowControllerHeight,
  currentSizeMode,
} from "./helper/layout-helper";
import { TabView } from "./component/tab/TabView";
import { getPublicUrl } from "./helper/url";

const APP_MINIMIZED_WIDTH = 60;
const APP_MINIMIZED_HEIGHT = 60;

function App() {
  const [isFullSize, setIsFullSize] = React.useState(false);
  const [streamSrc, setStreamSrc] = React.useState<{
    sourceType: string;
    sourceId: string;
  }>();
  const [shopOrigin, setShopOrigin] = React.useState<string>();
  const [logo, setLogo] = React.useState<string>("/img/logo-tv.png");

  React.useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.data.type === "window-size") {
        console.log(e.data);
        ls.set("windowDimension", {
          width: e.data.width,
          height: e.data.height,
        });
      }
    });

    fetch(
      "/api/getStreamerDetails?shopifyUrl=" +
        new URLSearchParams(window.location.search).get("shop")
    )
      .then((data) => data.json())
      .then((data) => {
        if (data && data.streamer) {
          setShopOrigin(data.streamer.shopOrigin);
          if (data.streamer.streamStatus === "A") {
            setStreamSrc({
              sourceType: data.streamer.streamSrc.sourceType,
              sourceId: data.streamer.streamSrc.sourceId,
            });
            if (
              data.streamer.displayOption &&
              data.streamer.displayOption.position
            ) {
              positionIframe(
                data.streamer.displayOption.position === "left"
                  ? {
                      bottom: 20,
                      left: 20,
                    }
                  : { bottom: 20, right: 20 }
              );
            }
            if (
              data.streamer.displayOption &&
              data.streamer.displayOption.logo
            ) {
              setLogo(data.streamer.displayOption.logo);
            }
            resizeIframe(APP_MINIMIZED_WIDTH, APP_MINIMIZED_HEIGHT);
            enableIframe();
          }
        }
      });
  }, []);

  const minWindowClick = () => {
    resizeIframe(
      getTotalWidth(currentSizeMode()) + 10,
      getTotalHeight(currentSizeMode()) + 10
    );
    setIsFullSize(true);
  };

  const fullWindowClick = () => {
    resizeIframe(APP_MINIMIZED_WIDTH, APP_MINIMIZED_HEIGHT);
    setIsFullSize(false);
  };

  const WindowTopBar = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "0 10px",
        height: getWindowControllerHeight() + "px",
      }}
    >
      <div style={{ paddingTop: "3px" }}>
        <i>Powered by</i>
        <img
          src={getPublicUrl() + "/img/shopshowy_text_logo.png"}
          height="8"
          alt="logo"
          style={{ marginLeft: "5px" }}
        />
      </div>
      <div>
        <span onClick={fullWindowClick} style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faWindowMinimize} />
        </span>
      </div>
    </div>
  );

  return streamSrc ? (
    <div
      className={styles.appContainer}
      style={{
        width: isFullSize ? "auto" : APP_MINIMIZED_WIDTH + "px",
        height: isFullSize ? "auto" : APP_MINIMIZED_HEIGHT + "px",
      }}
    >
      {isFullSize ? (
        <div className={styles.appBodyFull}>
          <div
            className={styles.streamContainer}
            style={{ flexDirection: isVerticalLayout() ? "column" : "row" }}
          >
            {isVerticalLayout() && <WindowTopBar />}
            <div
              style={{
                width: getStreamDivDimension(currentSizeMode()).width,
                height: getStreamDivDimension(currentSizeMode()).height,
              }}
            >
              {streamSrc.sourceType === "YOUTUBE" ? (
                <iframe
                  title="Live Stream"
                  width={getStreamDivDimension(currentSizeMode()).width}
                  height={getStreamDivDimension(currentSizeMode()).height}
                  src={`https://www.youtube.com/embed/${streamSrc.sourceId}?autoplay=1&mute=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : streamSrc.sourceType === "TWITCH" ? (
                <iframe
                  title="Live Stream"
                  src={`https://player.twitch.tv/?channel=${
                    streamSrc.sourceId
                  }&parent=${window.location.hostname}&parent=${(
                    new URLSearchParams(window.location.search).get("shop") ||
                    ""
                  ).replace(/https?:\/\//g, "")}`}
                  width={getStreamDivDimension(currentSizeMode()).width}
                  height={getStreamDivDimension(currentSizeMode()).height}
                  frameBorder="0"
                  scrolling="no"
                  allowFullScreen
                ></iframe>
              ) : streamSrc.sourceType === "FACEBOOK" ? (
                <iframe
                  title="Live Stream"
                  src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F${
                    streamSrc.sourceId
                  }%2F&show_text=false&width=${
                    getStreamDivDimension(currentSizeMode()).width
                  }&appId=219446011930919&height=${
                    getStreamDivDimension(currentSizeMode()).height
                  }`}
                  width={getStreamDivDimension(currentSizeMode()).width}
                  height={getStreamDivDimension(currentSizeMode()).height}
                  scrolling="no"
                  frameBorder="0"
                  allow="encrypted-media"
                ></iframe>
              ) : (
                <h3>Unknow stream source</h3>
              )}
            </div>
            <div
              style={{
                width: getTabDivDimension(currentSizeMode()).width + "px",
                height: getTabDivDimension(currentSizeMode()).height + "px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!isVerticalLayout() && <WindowTopBar />}
              <TabView />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.appBodyMinimized} onClick={minWindowClick}>
          <img
            src={getPublicUrl() + logo}
            alt="logo"
            style={{ maxWidth: "50px", maxHeight: "50px" }}
          />
        </div>
      )}
    </div>
  ) : null;
}

export default App;
