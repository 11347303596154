export enum ShopifyMessageType {
  ENABLE_WIDGET = "enable-shopshowy-widget",
  RESIZE_IFRAME = "resize-shopshowy-widget",
  POSITION_IFRAME = "position-shopshowy-widget",
}

interface EnableIframeMessage {
  type: ShopifyMessageType.ENABLE_WIDGET;
}

interface ResizeIframeMessage {
  type: ShopifyMessageType.RESIZE_IFRAME;
  width: number;
  height: number;
}

interface PositionIframeMessage {
  type: ShopifyMessageType.POSITION_IFRAME;
  position: { top?: number; right?: number; bottom?: number; left?: number };
}

export type ShopifyMessageData =
  | EnableIframeMessage
  | ResizeIframeMessage
  | PositionIframeMessage;

export const postMessageToShopify = (data: ShopifyMessageData): void => {
  if (
    window.parent &&
    new URLSearchParams(window.location.search).get("env") === "development"
  ) {
    window.parent.postMessage(data, "http://localhost:3000");
  } else if (
    window.parent &&
    new URLSearchParams(window.location.search).get("shop")
  ) {
    window.parent.postMessage(
      data,
      new URLSearchParams(window.location.search).get("shop") || ""
    );
  }
};
