import * as ls from "local-storage";

const WINDOW_CONTROLLER_HEIGHT = 20;
const TAB_HEIGHT = 35;

export const getTabHeight = () => TAB_HEIGHT;

export const getWindowControllerHeight = () => WINDOW_CONTROLLER_HEIGHT;

export const getWindowDimension = (): { width: number; height: number } => {
  return (
    ls.get("windowDimension") || {
      width: 1920,
      height: 1080,
    }
  );
};

export const isMobile = () =>
  getWindowDimension().width < 500 || getWindowDimension().height < 500;

export const isVerticalLayout = () =>
  getWindowDimension().width < getWindowDimension().height;

export const getStreamDivDimension = (size: "sm" | "lg") => {
  if (isVerticalLayout()) {
    const width =
      size === "lg"
        ? getWindowDimension().width - 40
        : Math.min(getWindowDimension().width / 2 - 20, 540);
    const height = Math.min(width * 0.6, getWindowDimension().height / 2 - 10);
    return {
      width,
      height,
    };
  } else {
    const height =
      size === "lg"
        ? getWindowDimension().height - 20
        : Math.min(getWindowDimension().height / 2 - 20, 315);
    const width = Math.min(height / 0.6, getWindowDimension().width / 2 - 20);
    return {
      width,
      height,
    };
  }
};

export const getTabDivDimension = (size: "sm" | "lg") => {
  if (isVerticalLayout()) {
    return {
      width: getStreamDivDimension(size).width,
      height: getStreamDivDimension(size).height * 1.5,
    };
  } else {
    return {
      width: getStreamDivDimension(size).width * 0.6,
      height: getStreamDivDimension(size).height,
    };
  }
};

export const getTotalWidth = (size: "sm" | "lg") =>
  isVerticalLayout()
    ? getStreamDivDimension(size).width
    : getStreamDivDimension(size).width + getTabDivDimension(size).width;

export const getTotalHeight = (size: "sm" | "lg") =>
  !isVerticalLayout()
    ? getStreamDivDimension(size).height
    : getStreamDivDimension(size).height +
      getTabDivDimension(size).height +
      getWindowControllerHeight();

export const currentSizeMode = () => (isMobile() ? "lg" : "sm");
