import * as React from "react";
import styles from "./Product.module.scss";
import io from "socket.io-client";
import {
  getTabDivDimension,
  currentSizeMode,
  isVerticalLayout,
} from "../../helper/layout-helper";

export interface ShopifyProduct {
  productId: string;
  title: string;
  thumbnailSrc: string;
  status: string;
  source: string;
  handle: string;
  shopOrigin: string;
  priceRange: { min: number; max: number };
}

export const ProductList = () => {
  const [productList, setProductList] = React.useState<ShopifyProduct[]>([]);

  const loadProducts = () => {
    fetch(
      "/api/getShopifyProducts?shopifyUrl=" +
        new URLSearchParams(window.location.search).get("shop")
    )
      .then((data) => data.json())
      .then((data) => {
        if (data) {
          console.log(data);
          setProductList(data.products || []);
        }
      });
  };

  const productClick = (product: ShopifyProduct) => () => {
    window.open(
      "https://" + product.shopOrigin + "/products/" + product.handle,
      "_blank"
    );
  };

  React.useEffect(() => {
    const socket = io();
    socket.on("productUpdate", () => {
      console.log("product update event received");
      loadProducts();
    });
    loadProducts();
  }, []);

  return productList && productList.length === 0 ? (
    <div>No relevant product.</div>
  ) : (
    <ul
      className={styles.productList}
      style={{
        height: `${
          getTabDivDimension(currentSizeMode()).height -
          (isVerticalLayout() ? 45 : 75)
        }px`,
      }}
    >
      {productList.map((product: ShopifyProduct) => (
        <li onClick={productClick(product)}>
          <div>
            <img
              src={product.thumbnailSrc}
              width="50"
              height="50"
              alt="Product"
              style={{ objectFit: "cover", borderRadius: "50%" }}
            />
          </div>
          <div>
            <div
              style={{
                marginLeft: "5px",
                fontSize: "0.9em",
                fontWeight: "bold",
              }}
            >
              {product.title}
            </div>
            <div style={{ fontStyle: "italic", textAlign: "right" }}>
              {product.priceRange && (
                <span style={{ marginLeft: "5px", fontSize: "0.9em" }}>
                  {product.priceRange.min === product.priceRange.max
                    ? `$${product.priceRange.min}`
                    : `$${product.priceRange.min} - $${product.priceRange.max}`}
                </span>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
